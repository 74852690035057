<script
    lang="ts"
    setup
>
    type Props = {
        modelValue: any
        name: string
        error?: string
        label?: string
        placeholder?: string
        disabled?: boolean
        loading?: boolean
        large?: boolean
        medium?: boolean
        small?: boolean
    }

    type Emit = {
        (event: 'update:modelValue', value: any): void
        (event: 'update:error', value: string): void
    }

    const props = withDefaults(defineProps<Props>(), {
        error: '',
        label: undefined,
        placeholder: undefined,
        disabled: false,
        loading: false,
    })

    const emit = defineEmits<Emit>()

    const inputModel = computed({
        get() {
            return props.modelValue
        },
        set(value) {
            if (props.error) {
                emit('update:error', '')
            }

            emit('update:modelValue', value)
        },
    })
</script>

<template>
    <AppFormFieldContainer
        textarea
        :textarea-large="props.large"
        :textarea-medium="props.medium"
        :textarea-small="props.small"
        :disabled="props.disabled"
        :loading="props.loading"
        :error-message="props.error"
        :class="[
            $style['textarea-container'],
            { [$style['textarea-container--has-value']]: inputModel },
        ]"
    >
        <template #default="{ inputClass, labelClass }">
            <textarea
                ref="inputRef"
                key="input"
                v-model="inputModel"
                :name="props.name"
                :disabled="props.disabled"
                :placeholder="props.placeholder || ' '"
                autocomplete="off"
                :class="[ inputClass, $style['textarea-container__textarea'] ]"
            ></textarea>

            <span
                v-if="props.label"
                key="label"
                :class="[ labelClass, $style['textarea-container__label'] ]"
            >
                {{ props.label }}
            </span>
        </template>

        <template
            v-if="$slots['bottom']"
            #bottom
        >
            <slot name="bottom" />
        </template>
    </AppFormFieldContainer>
</template>

<style
    lang="sass"
    module
    scoped
>
    @use 'assets/styles/sass/placeholders'

    .textarea-container
        &:focus-within,
        &--has-value
            --label-padding-top: 0

        &__textarea
            @extend %hide-scroll

            resize: none

        &__label
            padding-top: var(--label-padding-top, 8px)
            transition: padding-top 80ms var(--transition-default-ease)
            will-change: padding-top
</style>
